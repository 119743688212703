import { usePage } from '@inertiajs/react';
import { useTranslation } from 'react-i18next';

import { T_PageProps } from '~/types/T_PageProps';

export function useTranslationWithOrganizationContext() {
  const { organization } = usePage<T_PageProps>().props;
  const { t } = useTranslation();

  return {
    t(key: string, options?: {}) {
      if (options?.context) {
        throw new Error('Context is already determined by useTranslationWithOrganizationContext');
      }
      return t(key, { context: organization.kind, ...options });
    },
  };
}
