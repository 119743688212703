import { usePage } from '@inertiajs/react';

import { TypographySmall } from '~/components/typography/small';
import { DataTableContainer } from '~/components/ui-extended/data-table-container';
import { useTranslationWithOrganizationContext } from '~/hooks/useTranslationWithOrganizationContext.js';

import { LicenseInformation } from './license-information';

const tPathCommonNouns = 'common.nouns';
const tPathLicenseShow = 'pages.licenseShow';

export function PageContent() {
  const { access, book } = usePage<any>().props;
  const { t } = useTranslationWithOrganizationContext();

  const tLicenseShow = (key: string) => t(`${tPathLicenseShow}.${key}`);
  const tCommonNouns = (key: string) => t(`${tPathCommonNouns}.${key}`);

  const accessType = access.student ? tCommonNouns(`student`) : tCommonNouns(`teacher`);

  return (
    <DataTableContainer title={tLicenseShow(`pageHeader`)} translateTitle>
      {(access.owner || access.author) && (
        <>
          <TypographySmall>{tLicenseShow(`author_text1`)}</TypographySmall>
          <br />
          <TypographySmall>{tLicenseShow(`author_text2`)}</TypographySmall>
        </>
      )}

      {(access.student || access.teacher) && (
        // This is currently a table but could be a card in the future
        <LicenseInformation
          code={access.license.code}
          accessType={accessType}
          title={book.title}
          claimedAt={access.license.claimed_at}
          expiresAt={access.license.expires_at}
        />
      )}
    </DataTableContainer>
  );
}
