import React from 'react';

import { templateIconMap } from '~/components/pages-shared/template-icon-map';
import { E_TemplateIcon } from '~/types/T_TemplateIcon';
import T_TemplateIcons from '~/types/T_TemplateIcons';
import { cn } from '~/utils';

type Props = {
  onSelect: (color: string) => void;
  selectedIcon: string;
};

export function IconPicker(props: Props) {
  const { onSelect, selectedIcon } = props;

  return (
    <div className="bg-white grid grid-cols-8 gap-2 h-32 pr-4 overflow-y-scroll">
      <IconButton icon={null} onSelect={() => onSelect(null)} isSelected={selectedIcon === null}>
        <div className="w-6" />
      </IconButton>

      {(Object.keys(E_TemplateIcon) as T_TemplateIcons).map((icon) => {
        const IconComponent = templateIconMap[icon];

        return (
          <IconButton
            onSelect={() => onSelect(icon)}
            icon={icon}
            key={icon}
            isSelected={icon === selectedIcon}>
            <IconComponent className="w-5 h-5 text-black" />
          </IconButton>
        );
      })}
    </div>
  );
}

type ButtonProps = {
  children: React.ReactNode;
  icon: string;
  isSelected: boolean;
  onSelect: () => void;
};

function IconButton(props: ButtonProps) {
  const { icon, onSelect, isSelected, children } = props;

  return (
    <button
      type="button"
      onClick={onSelect}
      data-testid={`book-edit-icon-${icon}-button`}
      className={cn(
        'p-3 rounded border-gray-400 border flex items-center justify-center',
        isSelected && 'border-black bg-stone-100',
      )}>
      {children}
    </button>
  );
}
