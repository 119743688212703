import { zodResolver } from '@hookform/resolvers/zod';
import { router } from '@inertiajs/react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import LicenseGroupsApi from '~/api/LicenseGroupsApi';
import { Button } from '~/components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '~/components/ui/form';
import { Input } from '~/components/ui/input';
import { useTranslationWithOrganizationContext } from '~/pages/license-groups/new/useTranslationWithOrganizationContext';
import { T_Book, T_InvoiceDetails } from '~/types/serializers';

import { FormInputField } from './form-input-field';

type Props = {
  book: T_Book;
  handleUnselect: () => void;
  licenseForm: string;
  invoiceDetails?: T_InvoiceDetails;
};

const translationPath = 'pages.licenseGroupsIndex.forms.newLicenseGroup.labels';

export function NewLicenseGroupForm(props: Props) {
  const { book, licenseForm, handleUnselect, invoiceDetails } = props;
  const { t } = useTranslationWithOrganizationContext();

  function translate(key: string) {
    return t(`${translationPath}.${key}`);
  }

  const studentLicenses = licenseForm == 'student';

  const formSchema = z.object({
    new_license_count: z.string().min(1).max(1000),
    role: z.enum(['teacher', 'student']),
    notes: z.string().optional(),
    test_group: z.boolean().optional(),
    ...(studentLicenses
      ? {
          invoice_name: z.string(),
          invoice_email: z.string(),
          invoice_address: z.string(),
          invoice_vat_number: z.string(),
        }
      : {}),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      new_license_count: '1',
      role: licenseForm == 'teacher' ? 'teacher' : 'student',
      notes: '',
      test_group: !studentLicenses,
      ...(studentLicenses
        ? {
            invoice_name: invoiceDetails?.name,
            invoice_email: invoiceDetails?.email,
            invoice_address: invoiceDetails?.address,
            invoice_vat_number: invoiceDetails?.vat,
          }
        : {}),
    },
  });

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-5 overflow-y-auto">
        <FormField
          control={form.control}
          name="new_license_count"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{translate('amount')}</FormLabel>
              <FormControl>
                <Input min={1} max={licenseForm == 'trial' ? 30 : 1000} type="number" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="notes"
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                {t('models.licenseGroup.notes')}{' '}
                <span className="text-gray-400 font-light">({t('common.nouns.optional')})</span>
              </FormLabel>
              <FormControl>
                <Input
                  type="text"
                  placeholder={t(
                    'pages.licenseGroupsIndex.forms.newLicenseGroup.placeholders.notes',
                  )}
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        {licenseForm == 'student' && (
          <div className="space-y-1">
            <h3 className="font-semibold text-stone-700 border-b pb-2 mb-2">
              {translate('invoiceDetails')}
            </h3>

            {/* add component for form input */}
            <FormInputField
              inputType={'text'}
              fieldName={'invoice_name'}
              placeholder={''}
              inputLabel={'invoiceName'}
              control={form.control}
            />
            <FormInputField
              inputType={'email'}
              fieldName={'invoice_email'}
              placeholder={''}
              inputLabel={'invoiceEmail'}
              control={form.control}
            />
            <FormInputField
              inputType={'text'}
              fieldName={'invoice_address'}
              placeholder={''}
              inputLabel={'invoiceAddress'}
              control={form.control}
            />
            <FormInputField
              inputType={'text'}
              fieldName={'invoice_vat_number'}
              placeholder={''}
              inputLabel={'invoiceVATNumber'}
              control={form.control}
            />
          </div>
        )}

        <div className="flex justify-between">
          <Button data-testid="create_license_group_button" type="submit">
            {t('common.buttons.add')}
          </Button>
          <Button data-testid="close_license_form" variant="outline" onClick={handleUnselect}>
            {t('common.buttons.cancel')}
          </Button>
        </div>
      </form>
    </Form>
  );

  function onSubmit(values: z.infer<typeof formSchema>) {
    router.post(LicenseGroupsApi.create.path({ book_slug: book.slug }), values, {
      replace: true,
      onSuccess: () => {
        form.reset();
        console.log('success', book.slug);
      },
    });
  }
}
