// JsFromRoutes CacheKey 0b3d38d9d9109cdcdda0a27dfcc97938
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client';

export default {
  index: /* #__PURE__ */ definePathHelper('get', '/'),
  license: /* #__PURE__ */ definePathHelper('get', '/b/:slug/license'),
  discussions: /* #__PURE__ */ definePathHelper('get', '/b/:slug/discussions'),
  editStructure: /* #__PURE__ */ definePathHelper('get', '/b/:slug/edit_structure'),
  search: /* #__PURE__ */ definePathHelper('get', '/b/:slug/search'),
  archive: /* #__PURE__ */ definePathHelper('patch', '/b/:slug/archive'),
  unarchive: /* #__PURE__ */ definePathHelper('patch', '/b/:slug/unarchive'),
  duplicate: /* #__PURE__ */ definePathHelper('patch', '/b/:slug/duplicate'),
  claimLicense: /* #__PURE__ */ definePathHelper('post', '/b/claim_license'),
  create: /* #__PURE__ */ definePathHelper('post', '/b'),
  new: /* #__PURE__ */ definePathHelper('get', '/b/new'),
  edit: /* #__PURE__ */ definePathHelper('get', '/b/:slug/edit'),
  show: /* #__PURE__ */ definePathHelper('get', '/b/:slug'),
  update: /* #__PURE__ */ definePathHelper('patch', '/b/:slug'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/b/:slug'),
};
