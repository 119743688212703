import { usePage } from '@inertiajs/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import BooksApi from '~/api/BooksApi';

export default function ExpiredBookItem(props) {
  const { t } = useTranslation();

  const { book } = props;

  return (
    <li
      data-testid={`book_${book.slug}`}
      key={book.id}
      className="flex mx-1.5 my-2 flex-col text-center bg-white brightness-75 hover:filter-none rounded-lg shadow w-40 relative">
      <div className="flex flex-col flex-1 overflow-hidden rounded-lg cursor-arrow">
        <div className="h-56">
          {book.coverUrl ? (
            <img className="object-fill w-full h-full" src={book.coverUrl} />
          ) : (
            <div className="flex items-center justify-center h-56 p-2 text-6xl font-semibold text-white bg-gray-700">
              {book.title[0]}
            </div>
          )}
        </div>

        <div className="p-3 truncate">
          <h3 data-tip={book.title} className="text-sm font-medium text-gray-700 truncate">
            {book.title}
          </h3>
        </div>
      </div>
    </li>
  );
}
