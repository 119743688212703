import { router } from '@inertiajs/react';
import { usePage } from '@inertiajs/react';
import { Cog } from '@styled-icons/open-iconic/Cog';
import React from 'react';

import BooksApi from '~/api/BooksApi';
import { Button } from '~/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '~/components/ui/dropdown-menu';
import { useTranslationWithOrganizationContext } from '~/hooks/useTranslationWithOrganizationContext';

export default function BookItem(props) {
  const { t } = useTranslationWithOrganizationContext();

  const { user } = usePage<any>().props;
  const { book, activeSet } = props;

  return (
    <>
      {activeSet === 'expired' && <ExpiredBookItem book={book} />}
      {activeSet !== 'expired' && (
        <ActiveOrArchivedBookItem
          book={book}
          activeSet={activeSet}
          user={user}
          handleArchivation={handleArchivation}
          handleUnarchivation={handleUnarchivation}
          navigateToBook={navigateToBook}
        />
      )}
    </>
  );

  function handleArchivation() {
    if (confirm(t('pages.booksIndex.confirmations.archiveBook'))) {
      router.patch(BooksApi.archive.path({ slug: book.slug }));
    }
  }

  function handleUnarchivation() {
    router.patch(BooksApi.unarchive.path({ slug: book.slug }));
  }

  function navigateToBook() {
    router.visit(BooksApi.show.path({ slug: book.slug }));
  }
}

function ExpiredBookItem(props) {
  const { book } = props;
  const bookItemStyling =
    'flex mx-1.5 my-2 flex-col text-center bg-white rounded-lg shadow w-40 relative';
  return (
    <li
      data-testid={`book_${book.slug}`}
      key={book.id}
      className={`${bookItemStyling} brightness-75 hover:filter-none`}>
      <div className="flex flex-col flex-1 overflow-hidden rounded-lg cursor-arrow">
        <div className="h-56">
          {book.coverUrl ? (
            <img className="object-fill w-full h-full" src={book.coverUrl} />
          ) : (
            <div className="flex items-center justify-center h-56 p-2 text-6xl font-semibold text-white bg-gray-700">
              {book.title[0]}
            </div>
          )}
        </div>
        <div className="p-3 truncate">
          <h3 data-tip={book.title} className="text-sm font-medium text-gray-700 truncate">
            {book.title}
          </h3>
        </div>
      </div>
    </li>
  );
}

function ActiveOrArchivedBookItem(props) {
  const { book, handleArchivation, handleUnarchivation, activeSet, user, navigateToBook } = props;
  const bookItemStyling =
    'flex mx-1.5 my-2 flex-col text-center bg-white rounded-lg shadow w-40 relative';
  return (
    <li data-testid={`book_${book.slug}`} key={book.id} className={`${bookItemStyling}`}>
      {user.role !== 'student' && (
        <BookOptionsButton
          handleArchivation={handleArchivation}
          handleUnarchivation={handleUnarchivation}
          book={book}
          activeSet={activeSet}
        />
      )}
      <div
        className="flex flex-col flex-1 overflow-hidden rounded-lg cursor-pointer"
        onClick={navigateToBook}>
        <div className="h-56">
          {book.coverUrl ? (
            <img className="object-fill w-full h-full" src={book.coverUrl} />
          ) : (
            <div className="flex items-center justify-center h-56 p-2 text-6xl font-semibold text-white bg-gray-700">
              {book.title[0]}
            </div>
          )}
        </div>
        <div className="p-3 truncate">
          <h3 data-tip={book.title} className="text-sm font-medium text-gray-700 truncate ">
            {book.title}
          </h3>
        </div>
      </div>
    </li>
  );

  function BookOptionsButton(props) {
    const { t } = useTranslationWithOrganizationContext();

    const bookOptionsScope = 'pages.booksIndex.menus.bookOptions';

    const translate = (key: string) => t(`${bookOptionsScope}.${key}`);

    const { handleArchivation, handleUnarchivation, book, activeSet } = props;
    return (
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            variant="secondary"
            size="icon"
            data-testid="book-options-button"
            className="absolute top-2 text-stone-700 right-2 p-0 h-6 w-6">
            <Cog className="w-4 h-4" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-56">
          <DropdownMenuLabel>{translate('title')}</DropdownMenuLabel>
          <DropdownMenuSeparator />
          {activeSet == 'active' && (
            <>
              <DropdownMenuItem data-testid="archive-book-button" onClick={handleArchivation}>
                {translate('buttons.archiveBook')}
              </DropdownMenuItem>
              <DropdownMenuItem
                onClick={() => router.patch(BooksApi.duplicate.path({ slug: book.slug }))}>
                {translate('buttons.duplicateBook')}
              </DropdownMenuItem>
            </>
          )}
          {activeSet == 'archived' && (
            <DropdownMenuItem data-testid="unarchive-book-button" onClick={handleUnarchivation}>
              {translate('buttons.activateBook')}
            </DropdownMenuItem>
          )}
        </DropdownMenuContent>
      </DropdownMenu>
    );
  }
}
