import React from 'react';
import { useTranslation } from 'react-i18next';

import { nodeIsEmpty } from '~/lib/node-renderer/nodeHelpers';
import { T_AnswerableOpenQuestion } from '~/types/node/exercise/answerables/open-question/T_AnswerableOpenQuestion';
import { NodeMap } from '~/views/book/NodeMap';

import { useStandardAnswerProps } from '../../context/AnswerablePropsContext';

type Props = {
  answerable: T_AnswerableOpenQuestion['ViewNode'];
  subExercise?: boolean;
};

export function StandardAnswer(props: Props) {
  const { t } = useTranslation();

  const { answerable } = props;

  const { showAnswer } = useStandardAnswerProps();

  if (showAnswer !== 'all' || nodeIsEmpty(answerable)) return null;

  return (
    <div
      data-testid="open-question-correct-answer"
      className="rounded-md space-y-2 bg-green-50 border mb-2 p-3 mt-2 [&_p]:text-sm [&_p]:p-0 [&_p]:m-0 [&_p]:text-gray-600">
      <div>
        <span className="text-sm text-gray-600 font-semibold mr-2">
          {t('pages.courseContentsShow.text.answer')}:{' '}
        </span>
        <NodeMap nodes={answerable.children} />
      </div>
    </div>
  );
}
