import React from 'react';

import { LayoutBook } from '~/components/ui-extended/layout-book';

import { PageContent } from './components/page-content';
import { useLicensePage } from './T_LicenseProps';

export default function Index() {
  const { access, book } = useLicensePage().props;

  return (
    <LayoutBook
      htmlHeadTitle={book.title}
      breadcrumbs={[
        { title: 'common.breadcrumbs.licenseShow', translateTitle: true, type: 'static' },
      ]}
      access={access}
      book={book}
      sidebarTitle={book.title}>
      <PageContent />
    </LayoutBook>
  );
}
