import { ColumnDef } from '@tanstack/react-table';
import { Translation } from 'react-i18next';

import { Badge } from '~/components/ui/badge';
import { DataTableColumnHeader } from '~/components/ui/data-table-column-header';
import { T_LicenseGroup } from '~/types/serializers';

import { CopyLicenseCodeButton } from './components/copy-license-code-button';

export const columns: ColumnDef<T_LicenseGroup>[] = [
  {
    accessorKey: 'code',
    header: ({ column }) => (
      <Translation>
        {(t) => (
          <DataTableColumnHeader
            className="pl-4"
            column={column}
            title={t('models.licenseGroup.code')}
          />
        )}
      </Translation>
    ),
    cell: ({ row }) => (
      <div className="w-[140px] text-sm pl-4 flex items-center">
        <span className="font-medium">{row.getValue('code')}</span>
        <CopyLicenseCodeButton code={row.getValue('code')} />
      </div>
    ),
    enableSorting: false,
  },
  {
    accessorKey: 'notes',
    header: ({ column }) => (
      <Translation>
        {(t) => <DataTableColumnHeader column={column} title={t('models.licenseGroup.notes')} />}
      </Translation>
    ),
    cell: ({ cell }) => {
      return <div className="w-[150px] font-light text-sm truncate">{cell.getValue()}</div>;
    },
    enableSorting: false,
  },
  {
    accessorKey: 'role',
    header: ({ column }) => (
      <Translation>
        {(t) => <DataTableColumnHeader column={column} title={t('models.licenseGroup.role')} />}
      </Translation>
    ),
    cell: ({ row }) => {
      const role = row.getValue('role') as 'student' | 'teacher';

      return (
        <Translation>
          {(t) => <Badge variant="outline">{t(`models.licenseGroup.role_${role}`)}</Badge>}
        </Translation>
      );
    },
  },
  {
    accessorKey: 'used',
    header: ({ column }) => (
      <Translation>
        {(t) => <DataTableColumnHeader column={column} title={t('models.licenseGroup.used')} />}
      </Translation>
    ),
    cell: ({ row }) => {
      const { licenseCount, claimedLicenseCount } = row.original;

      return (
        <div className="max-w-[150px]">
          {claimedLicenseCount} / {licenseCount}
        </div>
      );
    },
    enableSorting: false,
  },
];
