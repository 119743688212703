import { usePage } from '@inertiajs/react';

import { T_Access, T_Book, T_License } from '~/types/serializers';
import { T_PageProps } from '~/types/T_PageProps';
import { T_PagyMetadata } from '~/types/T_PagyMetadata';

export const useLicensePage = usePage<T_LicenseProps>;

export interface T_LicenseProps extends T_PageProps {
  access: T_Access;
  book: T_Book;
  license: T_License;
  created_at: string;
  expires_at: string;
  pagyMetadata: T_PagyMetadata;
}
