import React from 'react';
import { useTranslation } from 'react-i18next';

import EducationApi from '~/api/Website/EducationApi';
import OrganizationApi from '~/api/Website/OrganizationApi';

import { NavBar } from './nav-bar';

const navLinks = [
  { i18nKey: 'home', href: '/' },
  { i18nKey: 'pricing', href: EducationApi.pricing.path() },
  { i18nKey: 'contact', href: EducationApi.contact.path() },
  { i18nKey: 'trainings', href: EducationApi.trainings.path() },
  { i18nKey: 'tutorials', href: OrganizationApi.tutorials.path() },
];

export function HeaderEducation() {
  const { i18n } = useTranslation();

  if (i18n.language === 'nl') {
    if (navLinks.length <= 5) {
      navLinks.push({ i18nKey: 'jobs', href: EducationApi.jobs.path() });
    }
  } else {
    if (navLinks.length > 5) {
      navLinks.splice(5, 1);
    }
  }

  return <NavBar subtitle="" navLinks={navLinks} />;
}
