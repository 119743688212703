import { Link } from '@inertiajs/react';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import BooksApi from '~/api/BooksApi';

import ExpiredBookItem from './ExpiredBookItem';

export default function ExpiredBookList(props) {
  const { t } = useTranslation();

  const { searchString, books } = props;

  const renderBook = useCallback((book, index) => <ExpiredBookItem key={index} book={book} />, []);

  if (!books.length) {
    return (
      <div className="p-8 bg-white border rounded-md">
        {searchString ? (
          <React.Fragment>
            <p className="font-light text-gray-600">{t('pages.booksIndex.text.noResults')}</p>
            <Link
              className="items-center inline-block mt-1 rounded hover:underline"
              data-testid="add_license_link"
              href={BooksApi.index.path()}>
              <h3 className="text-sm font-semibold text-gray-700">
                {t('pages.booksIndex.forms.search.buttons.cancelBookSearch')}
              </h3>
            </Link>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <p className="font-light text-gray-600">{t('pages.booksIndex.text.noBooks')}</p>
          </React.Fragment>
        )}
      </div>
    );
  }

  if (!books.length) {
    return <div className="p-8 bg-white border rounded-md"></div>;
  }

  return (
    <div className="p-8 bg-white border rounded-md" data-testid="expired_books_list">
      {searchString && (
        <div className="mb-4">
          <h3 className="font-medium text-gray-700">
            {t('pages.booksIndex.text.resultsFor')}{' '}
            <span className="italic font-normal">&rsquo;{searchString}&rsquo;</span>.
          </h3>
          <Link
            className="items-center inline-block rounded hover:underline"
            href={BooksApi.index.path()}>
            <h3 className="text-sm font-medium text-gray-500">
              {t('pages.booksIndex.forms.search.buttons.cancelBookSearch')}
            </h3>
          </Link>
        </div>
      )}
      <ul role="list" className="flex flex-wrap">
        {books.map(renderBook)}
      </ul>
    </div>
  );
}
