import { router, usePage } from '@inertiajs/react';
import { t } from 'i18next';
import React, { useState } from 'react';

import LicenseGroupsApi from '~/api/LicenseGroupsApi';
import { Button } from '~/components/ui/button';
import { DataTableContainer } from '~/components/ui-extended/data-table-container';
import { useTranslationWithOrganizationContext } from '~/pages/license-groups/new/useTranslationWithOrganizationContext';
import { T_PageProps } from '~/types/T_PageProps';

import { useLicenseGroupsNewPage } from '../T_LicenseGroupsNew';
import { LicenseGroupCard } from './license-group-card';

export function PageContent() {
  const { invoiceDetails, book } = useLicenseGroupsNewPage().props;
  const { t } = useTranslationWithOrganizationContext();
  const [selectedForm, setSelectedForm] = useState('');

  function handleUnselect() {
    setSelectedForm('');
    return;
  }

  const licenseTypeArray = ['student', 'teacher', 'studentTrial'];

  return (
    <DataTableContainer
      title="pages.licenseGroupsIndex.forms.newLicenseGroup.title"
      translateTitle
      rightSideContent={
        <Button
          onClick={() => router.get(LicenseGroupsApi.index.path({ book_slug: book.slug }))}
          variant="outline">
          {t('common.buttons.back')}
        </Button>
      }>
      <div className="grid grid-cols-1 gap-10 md:grid-cols-2 md:gap-10 lg:grid-cols-3 lg:grid-flow-col pb-40">
        {licenseTypeArray.map((licenseType) => {
          // settings for when cards are selected or not
          const notSelected = selectedForm !== licenseType && selectedForm !== '';
          const selected = selectedForm === licenseType;
          const cardStyling = `flex flex-col pt-6 justify-between ${selected ? '' : 'h-96'}`;

          function handleSelectLicense() {
            setSelectedForm(licenseType);
            return selectedForm;
          }

          return (
            <LicenseGroupCard
              handleSelectLicense={handleSelectLicense}
              invoiceDetails={invoiceDetails}
              notSelected={notSelected}
              selected={selected}
              cardStyling={cardStyling}
              handleUnselect={handleUnselect}
              selectedForm={selectedForm}
              key={licenseType}
              licenseType={licenseType}
              book={book}
            />
          );
        })}
      </div>
    </DataTableContainer>
  );
}
