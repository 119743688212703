import { Button } from '~/components/ui/button';
import { Card, CardContent, CardTitle } from '~/components/ui/card';
import { useTranslationWithOrganizationContext } from '~/pages/license-groups/new/useTranslationWithOrganizationContext';
import { T_Book, T_InvoiceDetails } from '~/types/serializers';

import { NewLicenseGroupForm } from './new-license-group-form';

type Props = {
  book: T_Book;
  licenseType: string;
  invoiceDetails?: T_InvoiceDetails;
  handleUnselect: () => void;
  handleSelectLicense: () => void;
  selectedForm: string;
  notSelected: boolean;
  selected: boolean;
  cardStyling: string;
};

const translateCardInfo = 'pages.licenseGroupsIndex.forms.newLicenseGroup.cards';

export function LicenseGroupCard(props: Props) {
  const {
    book,
    handleSelectLicense,
    selectedForm,
    handleUnselect,
    invoiceDetails,
    licenseType,
    notSelected,
    selected,
    cardStyling,
  } = props;

  const { t } = useTranslationWithOrganizationContext();

  function translate(key: string) {
    return t(`${translateCardInfo}.${licenseType}.${key}`);
  }

  return (
    <Card className={notSelected ? `text-gray-400 ${cardStyling}` : `${cardStyling}`}>
      <CardTitle className="pt-3 pl-6">{translate('title')}</CardTitle>
      <CardContent>
        {/* card item */}
        <ul className="pt-6 pb-6">
          <li>{translate('cost')}</li>
          <br />
          <li>{translate('access')}</li>
          <br />
          <li>{translate('interaction')}</li>
          <br />
          <li>{translate('duration')}</li>
        </ul>
        <Button
          data-testid={licenseType == 'student' ? 'select_student_licenses' : ''}
          className={selected ? 'invisible' : ''}
          onClick={handleSelectLicense}>
          {t('common.nouns.select')}
        </Button>

        {licenseType == selectedForm && (
          <>
            <h3 className="font-semibold text-stone-700 border-b pb-2 pt-2 mb-2" key="">
              {translate('purchaseForm.title')}
            </h3>
            <p className="text-sm pt-1 pb-2">{translate('purchaseForm.description')}</p>
            {licenseType !== 'studentTrial' && (
              <>
                <p className="text-sm pt-1 pb-3">{translate('purchaseForm.extraInfo')} </p>
              </>
            )}
            <NewLicenseGroupForm
              licenseForm={licenseType}
              handleUnselect={handleUnselect}
              book={book}
              invoiceDetails={invoiceDetails}
            />
          </>
        )}
      </CardContent>
    </Card>
  );
}
