import dayjs from 'dayjs';

import { Table, TableBody, TableCell, TableHeader, TableRow } from '~/components/ui/table';
import { useTranslationWithOrganizationContext } from '~/hooks/useTranslationWithOrganizationContext';

type LicenseInformationProps = {
  code: string;
  accessType: string;
  title: string;
  claimedAt: string;
  expiresAt: string;
};

export function LicenseInformation(props: LicenseInformationProps) {
  return (
    <Table>
      <LicenseInfoHeader />
      <LicenseInfoBody {...props} />
    </Table>
  );
}

function LicenseInfoBody(props: LicenseInformationProps) {
  const { code, accessType, title, claimedAt, expiresAt } = props;

  return (
    <TableBody>
      <TableRow className="hover:bg-white">
        <TableCell>{code}</TableCell>
        <TableCell>{accessType}</TableCell>
        <TableCell className="max-w-[6rem] truncate">{title}</TableCell>
        <TableCell data-testid="license_attr_claimed_at">{dayjs(claimedAt).format('L')}</TableCell>
        <TableCell data-testid="license_attr_expires_at">{dayjs(expiresAt).format('L')}</TableCell>
      </TableRow>
    </TableBody>
  );
}

function LicenseInfoHeader() {
  const { t } = useTranslationWithOrganizationContext();
  const translationPath = 'pages.licenseShow';

  function translate(key: string) {
    return t(`${translationPath}.${key}`);
  }

  const tableColumns = ['code', 'accessType', 'title', 'claimedAt', 'expiresAt'];

  return (
    <TableHeader>
      <TableRow className="hover:bg-white">
        {tableColumns.map((columnHeader) => (
          <TableCell className="font-semibold" key={columnHeader}>
            {translate(columnHeader)}
          </TableCell>
        ))}
      </TableRow>
    </TableHeader>
  );
}
