import { router } from '@inertiajs/react';
import queryString from 'query-string';
import React, { useState } from 'react';
import { MagnifyingGlass } from 'styled-icons/heroicons-outline';

import BooksApi from '~/api/BooksApi';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '~/components/ui/tabs';
import { useTranslationWithOrganizationContext } from '~/hooks/useTranslationWithOrganizationContext';

import BookList from '../BookList';
import { useBooksIndexPage } from '../T_BooksIndex';
import { CreationOptions } from './create-options';

export function PageContent() {
  const { t } = useTranslationWithOrganizationContext();

  const bookTabsScope = 'pages.booksIndex.bookTabs';

  const translate = (key: string) => t(`${bookTabsScope}.${key}`);

  const { user, books, activeSet, sets } = useBooksIndexPage().props;

  const activeSearch = (queryString.parse(location.search)['search_string'] as string) || '';

  const [inputSearchString, setInputSearchString] = useState(activeSearch);

  return (
    <div className="flex flex-col w-full">
      <div className="max-w-3xl w-full mx-auto mt-10">
        <div className="mb-6">
          <h2 className="font-bold text-3xl text-gray-700">{t('pages.booksIndex.title')}</h2>
        </div>
        <div className="flex items-end justify-between mb-3">
          <SearchBar
            searchString={inputSearchString}
            setSearchString={setInputSearchString}
            handleSearch={handleSearch}
          />
          <CreationOptions role={user.role} />
        </div>
        <Tabs className="books" defaultValue={activeSet}>
          <TabsList
            className={
              user.role == 'teacher_or_author'
                ? `grid w-80 grid-cols-3 mb-2 tegreen`
                : `grid w-80 grid-cols-2 mb-2 tegreen`
            }>
            {sets.map((set) => {
              return (
                <>
                  <TabsTrigger
                    data-testid={`booklist_${set}`}
                    value={set}
                    onClick={(event) => handleSetChange(event, set)}>
                    {translate(`${set}`)}
                  </TabsTrigger>
                </>
              );
            })}
          </TabsList>
          <TabsContent className="w-full" value={activeSet}>
            <BookList books={books} activeSet={activeSet} activeSearch={activeSearch} />
          </TabsContent>
        </Tabs>
      </div>
    </div>
  );

  function handleSetChange(event, set) {
    event.preventDefault();

    requestIndex(activeSearch, set);
  }

  function handleSearch(event) {
    event.preventDefault();

    requestIndex(inputSearchString, activeSet);
  }

  function requestIndex(searchString, activeSet) {
    router.get(
      BooksApi.index.path({ query: { search_string: searchString, active_set: activeSet } }),
      {
        preserveState: true,
      },
    );
  }
}

function SearchBar(props) {
  const { searchString, setSearchString, handleSearch } = props;

  const { t } = useTranslationWithOrganizationContext();
  const searchFormScope = 'pages.booksIndex.forms.search';
  const translate = (key: string) => t(`${searchFormScope}.${key}`);

  return (
    <div>
      <div>
        <label htmlFor="search_books" className="block text-sm font-medium text-gray-700">
          {translate('labels.searchInput')}
        </label>
        <form onSubmit={handleSearch}>
          <div className="mt-1 flex rounded-md shadow-sm">
            <div className="relative flex items-stretch flex-grow focus-within:z-10">
              <input
                value={searchString}
                onChange={(e) => setSearchString(e.target.value)}
                type="text"
                name="search_books"
                id="search_books"
                className="focus:ring-indigo-500 focus:border-indigo-500 block w-full rounded-none rounded-l-md sm:text-sm border-gray-300"
                placeholder={translate('placeholders.searchInput')}
              />
            </div>
            <button
              type="submit"
              className="-ml-px relative inline-flex items-center space-x-1 px-3 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500">
              <MagnifyingGlass className="h-5 w-5 text-gray-400" aria-hidden="true" />
              <span>{translate('buttons.performBookSearch')}</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
