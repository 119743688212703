import i18n from 'i18next';
import React from 'react';
import { I18nextProvider, initReactI18next } from 'react-i18next';
import { i18nextPlugin } from 'translation-check';
import z from 'zod';
import { makeZodI18nMap } from 'zod-i18n-map';

import T_OrganizationKind from '~/types/T_OrganizationKind';

import { createContextPlugin } from './contextPlugin';
import { resources } from './resources';

type T_ProviderProps = {
  children: React.ReactNode;
  lng: 'en' | 'nl' | 'fr';
  context: T_OrganizationKind;
};

export function LanguageProvider({ children, context, lng }: T_ProviderProps) {
  const newInstance = i18n.createInstance();

  newInstance
    .use(initReactI18next)
    .use(i18nextPlugin)
    .use(createContextPlugin(context))
    .init({
      debug: false,
      fallbackLng: 'en',
      interpolation: { escapeValue: false },
      lng,
      resources,
      postProcess: ['contextProcessor'],
    });

  z.setErrorMap(
    makeZodI18nMap({
      t: newInstance.t,
      ns: ['translation', 'zod'],
      handlePath: {
        keyPrefix: 'forms',
      },
    }),
  );

  return <I18nextProvider i18n={newInstance}>{children}</I18nextProvider>;
}
