import { Control, FieldValues } from 'react-hook-form';

import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '~/components/ui/form';
import { Input } from '~/components/ui/input';
import { useTranslationWithOrganizationContext } from '~/pages/license-groups/new/useTranslationWithOrganizationContext';

type Props = {
  inputType: string;
  placeholder: string;
  fieldName: string;
  inputLabel: string;
  control: object;
};

export function FormInputField(props: Props) {
  const { t } = useTranslationWithOrganizationContext();
  const { inputType, placeholder, inputLabel, control, fieldName } = props;
  const translationPath = 'pages.licenseGroupsIndex.forms.newLicenseGroup.labels';

  function translate(key: string) {
    return t(`${translationPath}.${key}`);
  }

  return (
    <FormField
      key={fieldName}
      control={control as Control<FieldValues>}
      name={fieldName}
      render={({ field }) => (
        <FormItem>
          <FormLabel className="w-1/4">{translate(inputLabel)}</FormLabel>
          <FormControl>
            <Input type={inputType} placeholder={placeholder} required={true} {...field} />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}
