import { Link } from '@inertiajs/react';
import { PlusIcon } from '@radix-ui/react-icons';
import React from 'react';
import { useTranslation } from 'react-i18next';

import LicenseGroupsApi from '~/api/LicenseGroupsApi';
import { Button } from '~/components/ui/button';

import { useLicenseGroupsIndexPage } from '../T_LicenseGroupsIndex';

export function NewLicenseGroupButton() {
  const { t } = useTranslation();

  const { book } = useLicenseGroupsIndexPage().props;

  return (
    <div>
      <Button
        data-testid="new_license_group_button"
        className="second-step ml-2"
        asChild
        variant="outline">
        <Link href={LicenseGroupsApi.new.path({ book_slug: book.slug })}>
          <PlusIcon className="h-4 w-4 mr-2" />
          {t('pages.licenseGroupsIndex.dialogs.newLicenseGroup.title')}
        </Link>
      </Button>
    </div>
  );
}
