import { zodResolver } from '@hookform/resolvers/zod';
import { router, usePage } from '@inertiajs/react';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

import BooksApi from '~/api/BooksApi';
import { CardContent, CardFooter } from '~/components/ui/card';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '~/components/ui/form';
import { Input } from '~/components/ui/input';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '~/components/ui/select';
import { Switch } from '~/components/ui/switch';
import { ActionButton } from '~/components/ui-extended/action-button';

const MAX_UPLOAD_SIZE = 1024 * 1024 * 7; // 7MB

const formSchema = z.object({
  title: z.string().refine((data) => data.length >= 2, {
    params: { i18n: 'customZodErrors.min2chars' },
  }),
  printable: z.boolean(),
  cover: z.any().refine((file) => !file || file.size < MAX_UPLOAD_SIZE, {
    params: { i18n: 'customErrors.maxFileSize7' },
  }),
  language: z.string(),
});

export function NewBookForm() {
  const { auth } = usePage().props;

  const { t } = useTranslation();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      cover: null,
      printable: false,
      title: '',
      language: auth.user.language,
    },
  });

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <CardContent className="space-y-4">
          <FormField
            control={form.control}
            name="title"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('pages.booksNew.forms.newBook.labels.title')}</FormLabel>
                <FormControl>
                  <Input
                    placeholder={t('pages.booksNew.forms.newBook.placeholders.title')}
                    type="text"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="language"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('pages.booksEdit.forms.general.labels.language')}</FormLabel>
                <Select onValueChange={field.onChange} defaultValue={field.value}>
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    <SelectItem value="en">English</SelectItem>
                    <SelectItem value="nl">Nederlands</SelectItem>
                    <SelectItem value="fr">Français</SelectItem>
                  </SelectContent>
                </Select>
                <FormDescription>
                  {t('pages.booksEdit.forms.general.descriptions.language')}
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="printable"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-sm">
                  {t('pages.booksNew.forms.newBook.labels.print')}
                </FormLabel>

                <div className="flex flex-row items-center justify-between rounded-lg border p-4">
                  <FormLabel className="font-normal text-sm">
                    {t('pages.booksNew.forms.newBook.labels.bookCanBePrinted')}
                  </FormLabel>

                  <FormControl>
                    <Switch checked={field.value} onCheckedChange={field.onChange} aria-readonly />
                  </FormControl>
                </div>
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="cover"
            render={({ field: { value, ...field } }) => (
              <FormItem>
                <FormLabel>{t('pages.booksNew.forms.newBook.labels.cover')}</FormLabel>
                <FormControl>
                  <Input
                    {...field}
                    onChange={handleImageSelection}
                    value={value?.fileName}
                    accept=".tif,.tiff,.jpg,.jpeg,.png"
                    type="file"
                  />
                </FormControl>
                <FormDescription>
                  <ul className="text-gray-600 font-light text-xs mt-2">
                    <li className="my-1 font-medium">
                      {t('pages.booksNew.forms.newBook.descriptions.coverRatio')}
                    </li>
                    <li>{t('pages.booksNew.forms.newBook.descriptions.coverMaxSize')}</li>
                    <li>{t('pages.booksNew.forms.newBook.descriptions.coverExtensions')}</li>
                  </ul>
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
        </CardContent>

        <CardFooter className="border-t px-6 py-4">
          <ActionButton type="submit">
            {t('pages.booksNew.forms.newBook.buttons.createBook')}
          </ActionButton>
        </CardFooter>
      </form>
    </Form>
  );

  function handleImageSelection(event: React.ChangeEvent<HTMLInputElement>) {
    form.setValue('cover', event.target.files[0], { shouldValidate: true, shouldTouch: true });
  }

  function onSubmit(values: z.infer<typeof formSchema>) {
    router.post(BooksApi.create.path(), values, {
      onError: (err) => {
        console.log(err);
      },
    });
  }
}
